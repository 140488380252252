<template>
  <div class="footer">© Copyright 2016-2021 www.100doc.com.cn All right reserved.</div>
</template>

<script>
export default {
name: 'Footer'
}
</script>

<style scoped lang="scss">
.footer {
  height: 52px;
  text-align: center;
  background: #FFFFFF;
  width: 100%;
  color: #999999;
  font-size: 14px;
  line-height: 52px;
  border-top: 1px solid #EEEEEE;
  user-select: none;
}
</style>
